import React from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
} from "recharts";
import Title from "./Title";

// Generate Sales Data
function createData(time, amount) {
  return { time, amount };
}

// Create a function to get array of last 30 days from today
function getDaysArray() {
  var date = new Date();
  var days = [];
  for (var i = 30; i > 0; i--) {
    var d = new Date(date.getTime() - i * 24 * 60 * 60 * 1000);
    // Format date to MM/DD
    var month = d.getMonth() + 1;
    var day = d.getDate();

    var dateStr = day + "." + month;
    days.push({ time: dateStr, amount: i });
  }
  console.log("Days: " + JSON.stringify(days));
  return days;
}

/*
const data = [
  createData("00:00", 0),
  createData("03:00", 0),
  createData("06:00", 0),
  createData("09:00", 0),
  createData("12:00", 0),
  createData("15:00", 0),
  createData("18:00", 0),
  createData("21:00", 0),
  createData("24:00", undefined),
];
*/

const data = getDaysArray();

export default function Chart() {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Title>Last 30 days</Title>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary}>
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
            >
              SMS amount
            </Label>
          </YAxis>
          <Line
            type="monotone"
            dataKey="amount"
            stroke={theme.palette.primary.main}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
