import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { sendSms } from "../http/userAPI";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "35ch",
    },
  },
}));

export default function SendSmsComponent(props) {
  const classes = useStyles();
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [fromNumber, setFromNumber] = useState("");
  const [text, setText] = useState("");

  const handleSendSms = async () => {
    let toNumber = [];
    toNumber.push(phoneNumbers);
    await sendSms(toNumber, fromNumber, text, props.hashData._key);
  };
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        id="outlined-basic"
        label="Send To"
        variant="outlined"
        value={phoneNumbers}
        onChange={(e) => setPhoneNumbers(e.target.value)}
      />
      <TextField
        id="outlined-basic"
        label="Send From"
        variant="outlined"
        value={fromNumber}
        onChange={(e) => setFromNumber(e.target.value)}
      />
      <TextField
        variant="outlined"
        id="standard-multiline-flexible"
        label="Text"
        multiline
        rowsMax={4}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={handleSendSms}>
        Send SMS
      </Button>
    </form>
  );
}
