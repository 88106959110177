import { $authHost, $host } from "./index";
import jwt_decode from "jwt-decode";
import firebase from "../utils/firebase-config";
import axios from "axios";

export const check = async () => {
  var currentUser = await firebase.auth().currentUser;
  var currentUserGetId = await firebase.auth().currentUser.getIdTokenResult();
  console.log("CurrentUser: " + JSON.stringify(currentUser));
  if (currentUser) {
    console.log("CurrentUserGetId: " + JSON.stringify(currentUserGetId));
    console.log("Data: " + JSON.stringify(currentUser));
    console.log("Data2: " + JSON.stringify(currentUser.data.stsTokenManager));
    localStorage.setItem("token", currentUser.stsTokenManager.accessToken);
    return jwt_decode(currentUser.stsTokenManager.accessToken);
  } else {
    return "Test!";
  }
};

export const payment = async (amount, paymentId, userName) => {
  const data = await $authHost.post("stripePayment", {
    amount,
    paymentId,
    userName,
  });

  /* const data = await axios.post(
    "http://localhost:5001/my-new-project-1344/us-central1/stripePayment",
    { amount, paymentId }
  ); */
  return data.data;
};

export const topupOldAccount = async (
  amount,
  paymentId,
  userName,
  actionType
) => {
  const data = await $host.post("stripePaymentOldAccount", {
    amount,
    paymentId,
    userName,
    actionType,
  });

  /* const data = await axios.post(
    "http://localhost:5001/my-new-project-1344/us-central1/stripePayment",
    { amount, paymentId }
  ); */
  return data.data;
};

export const transferBetweenAccounts = async (userId, hashId, sum) => {
  const data = await $authHost.post("transferBetweenAccounts", {
    userId,
    hashId,
    sum,
  });
  return data.data;
};

export const sendSms = async (toNumber, fromNumber, text, hashId) => {
  /*
  const data = await $authHost.post("sendsms", {
    toNumber,
    fromNumber,
    text,
    hash: hashId,
  });
  */

  let payload = {
    toNumber,
    fromNumber,
    text,
    hash: hashId,
  };

  let data = await axios.post("https://api.smspm.com/v1/sendsms", payload);

  console.log(toNumber, fromNumber, text, hashId);
  console.log(data.data);
  return data.data;
};

export const migrateOldAccount = async (userId, oldUserName, oldHash) => {
  const data = await $authHost.post("migrateAccounts", {
    userId,
    oldUserName,
    oldHash,
  });
  return data.data;
};

export const transferToAccount = async (userId, amount) => {
  const data = await $authHost.post("transferToOldAccount", {
    userId,
    amount,
  });
  return data.data;
};

export const updatePricelist = async (routeId, providerId, price) => {
  let data = await axios.get(
    "https://smspm.com/56673378/api/pricelist_update.php?route_id=11111" +
      routeId +
      "&provider=" +
      providerId +
      "&price=" +
      price
  );

  console.log(data.data);
  return data.data;
};

export const getPrices = async (hash) => {
  const data = await $authHost.post("prices", { hash });
  return data.data;
};

export const getPricesKV = async () => {
  const data = await $authHost.get("pricesKV");
  return data.data;
};

export const addKV = async (obj) => {
  console.log("Obj; ", obj);
  const data = await $authHost.post("addKV", { obj });
  return data.data;
  return "ok";
};

export const changeDefaultRoute = async (hash, carrier, value) => {
  const data = await $authHost.post("changeDefaultRoute", {
    hash,
    carrier,
    value,
  });
  return data.data;
};

export const getHash = async () => {
  const data = await $authHost.post("getHash", {});
  return data.data;
};
