import { observer } from "mobx-react-lite";
import React, { useState, useContext } from "react";
import { Grid, Paper, Button, Dialog, DialogTitle } from "@material-ui/core";

import { QRCodeSVG } from "qrcode.react";
import { Context } from "../index";

const Devices = observer(() => {
  const { user } = useContext(Context);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={3}>
          <Paper>
            Connect your first device
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Connect
            </Button>
          </Paper>
        </Grid>
      </Grid>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Scan QR code with your app</DialogTitle>
        <QRCodeSVG value={user._user.uid} />
      </Dialog>
    </>
  );
});

export default Devices;
