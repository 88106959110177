import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "./index";
import AppRouter from "./components/AppRouter";
import { check } from "./http/userAPI";
import { BrowserRouter } from "react-router-dom";
import firebase from "./utils/firebase-config";
import { getHash } from "./http/userAPI";

const App = observer(() => {
  const { user } = useContext(Context);
  const { role } = useContext(Context);
  const { hash } = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((userCheck) => {
      if (userCheck) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        console.log("UID: " + JSON.stringify(userCheck));

        getHash().then((response) => {
          console.log("Get Hash 3: " + JSON.stringify(response.result));
          hash.setHash(response);

          firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((response) => {
              console.log("ID response: " + JSON.stringify(response));
              if (typeof response.claims.type != "undefined") {
                console.log("Claims: " + response.claims.type);
                role.setRole(response.claims.type);
              }
            });
          user.setUser(userCheck);
          user.setIsAuth(true);

          userCheck.getIdToken().then((token) => {
            localStorage.setItem("token", token);
            // user.setToken(token);
            console.log(token);
          });
          setLoading(false);
        });
      } else {
        // User is signed out
        // ...
        setLoading(false);
      }
    });

    // check()
    //  .then((data) => {
    //    console.log("User: " + JSON.stringify(user));
    //    user.setUser(data);
    //    user.setIsAuth(true);
    //  })
    //  .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return "Loading...";
  }
  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
});

export default App;
