import React, { useContext, useEffect, useState } from "react";
import { Context } from "../index";
import { observer } from "mobx-react-lite";
import firebase from "../utils/firebase-config";
import "firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ADMIN_ROUTE,
  LOGIN_ROUTE,
  HASH_ROUTE,
  TOPUP_ROUTE,
} from "../utils/consts";
import { useHistory } from "react-router-dom";
import { Avatar, Menu, MenuItem } from "@material-ui/core";
import { getHash } from "../http/userAPI";
import { set } from "mobx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const ProfileMenu = observer(() => {
  const { user } = useContext(Context);
  const { role } = useContext(Context);
  const { hash } = useContext(Context);
  const { defaultHash } = useContext(Context);

  const history = useHistory();
  const classes = useStyles();
  const [hashData, setHashData] = useState([]);
  const [tempHashData, setTempHashData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hashMenuOpen, setHashMenuOpen] = useState(null);
  const [selectedHash, setSelectedHash] = useState("");
  const [selectedHash2, setSelectedHash2] = useState("");
  const [selectedHashId, setSelectedHashId] = useState("");
  const [selectedHashName, setSelectedHashName] = useState("");
  const [dbHashData, setDbHashData] = useState([]);

  useEffect(() => {
    console.log("Get Hash 5: " + JSON.stringify(hash._hash));
    firebase.auth().onAuthStateChanged((userCheck) => {
      if (userCheck) {
        console.log("Get Hash 41: " + JSON.stringify(hash._hash));
        setSelectedHash2(hash._hash.result);
        setLoading(false);
        /*
        async function fetchHash() {
          let response = await getHash();
          console.log("Get Hash 4: " + JSON.stringify(response.result));
          let tempHash = response.result;
          await hash.setHash(response.result);
          setSelectedHash2(tempHash[0]);
        }

        fetchHash().then(() => {
          setLoading(false);
        });
        */
      }
    });
  }, []);

  /*
  useEffect(() => {
    let selHashId;
    var open = indexedDB.open("smspmDb", 3);
    open.onsuccess = function () {
      // Start a new transaction
      var db = open.result;

      var tx1 = db.transaction("smspmHash", "readwrite");
      var store1 = tx1.objectStore("smspmHash");
      var dbResults = store1.getAll();
      dbResults.onsuccess = function () {
        console.log(
          "DBout: " + user._user.uid,
          JSON.stringify(dbResults.result)
        );
        setDbHashData(dbResults.result);
      };

      // console.log("Typeof: " + db.transaction("SelectedKey", "readwrite"));
      if (typeof db.transaction("SelectedKey", "readwrite") != "undefined") {
      }
      open.onupgradeneeded = function () {
        console.log("Test5678");
        db = open.result;
        db.createObjectStore("SelectedKey", {
          keyPath: "id",
        });
      };

      var tx = db.transaction("SelectedKey", "readwrite");
      var store = tx.objectStore("SelectedKey");
      const selectedHashTemp = store.get(1);
      selectedHashTemp.onsuccess = function () {
        selHashId = selectedHashTemp.result.selected;
        console.log("selHashId1: " + selHashId);
        setSelectedHashId(selectedHashTemp.result.selected);

        tx1 = db.transaction("smspmHash", "readwrite");
        store1 = tx1.objectStore("smspmHash");
        const selectedHashNameTemp = store1.get(selHashId);
        selectedHashNameTemp.onsuccess = function () {
          setSelectedHash(selectedHashNameTemp.result);
          console.log(
            "selectedHashNameTemp.result: " +
              JSON.stringify(selectedHashNameTemp.result)
          );
          // setLoading(false);
        };
      };
    };
    open.onerror = function (event) {
      alert(event);
    };
  }, []);
  */

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
    user.setUser({});
    user.setIsAuth(false);
    localStorage.removeItem("token");
    handleClose();
    history.push(LOGIN_ROUTE);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleHashMenu = (event) => {
    setHashMenuOpen(event.currentTarget);
    console.log("HashData: " + JSON.stringify(hashData));
    console.log("temp HashData: " + JSON.stringify(tempHashData));
  };
  const handleHashMenuClick = (hashId, hashName, key) => {
    var open = indexedDB.open("smspmDb", 3);
    open.onupgradeneeded = function () {
      var db = open.result;
      var store = db.createObjectStore("SelectedKey", { keyPath: "id" });
    };
    open.onsuccess = function () {
      // Start a new transaction
      var db = open.result;
      var tx = db.transaction("SelectedKey", "readwrite");
      var store = tx.objectStore("SelectedKey");
      store.put({ id: 1, selected: hashId });
    };

    history.push(HASH_ROUTE + "/" + hashId);
    localStorage.setItem("selectedHash", key);
    localStorage.setItem("selectedHashId", hashId);
    setSelectedHashId(hashId);
    setSelectedHashName(hashName);

    setHashMenuOpen(null);
    console.log("Temp hash:" + tempHashData);
  };
  const handleHashMenuClose = () => {
    setHashMenuOpen(null);
  };

  // ToDo: Change to Macrometa
  const handleAddHash = () => {
    const db = firebase.firestore();
    const hashRef = db.collection("hash");
    hashRef
      .add({
        balance: 0,
        hashName: "Sub account",
        userId: user._user.uid,
      })
      .then((results) => {
        console.log("Resp: " + results);
        history.push(HASH_ROUTE + "/" + results.id);
      });
  };
  return (
    <>
      {user.isAuth ? (
        <>
          <Button color="inherit" onClick={handleHashMenu}>
            {hash._hash == ""
              ? "Loading..."
              : hash._hash.result[0].name +
                " (€" +
                hash._hash.result[0].balance +
                ")"}
            <ExpandMoreIcon />
          </Button>

          <Menu
            id="hash-menu"
            anchorEl={hashMenuOpen}
            keepMounted
            open={Boolean(hashMenuOpen)}
            onClose={handleHashMenuClose}
          >
            {console.log(
              "Hash 2: " + JSON.stringify(hash["_hash"] + " " + loading)
            )}
            {loading
              ? "Loading ..."
              : hash._hash.result.map((row, key) => (
                  <MenuItem key={key}>
                    <ArrowRightIcon />
                    {row.name}
                    {console.log("ROle: " + JSON.stringify(role._role))}
                  </MenuItem>
                ))}
            <MenuItem onClick={handleAddHash}>
              <AddIcon />
              Add sub account
            </MenuItem>
          </Menu>

          <IconButton color="inherit" onClick={handleClick}>
            <Avatar alt="Remy Sharp" src={user.photoURL} />
          </IconButton>
        </>
      ) : (
        <>
          <Button color="inherit" onClick={() => history.push(LOGIN_ROUTE)}>
            Login
          </Button>
        </>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Typography component="p" variant="h5">
          €33
        </Typography>
        <Link color="primary" onClick={() => history.push(TOPUP_ROUTE)}>
          Topup account
        </Link>
        {role._role === "admin" ? (
          <MenuItem color="inherit" onClick={() => history.push(ADMIN_ROUTE)}>
            Admin
          </MenuItem>
        ) : (
          ""
        )}
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
      {user.isAuth ? (
        ""
      ) : (
        <>
          <Button color="inherit" onClick={() => history.push(LOGIN_ROUTE)}>
            Login
          </Button>
        </>
      )}
    </>
  );
});

export default ProfileMenu;
