import firebase from "firebase/app";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyDhkqYJEp5bvCR35DcZ3iJeqvt3UQzMOiM",
  authDomain: "my-new-project-1344.firebaseapp.com",
  databaseURL: "https://my-new-project-1344-default-rtdb.firebaseio.com",
  projectId: "my-new-project-1344",
  storageBucket: "my-new-project-1344.appspot.com",
  messagingSenderId: "631074300819",
  appId: "1:631074300819:web:b504973628e068e0947e66",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
