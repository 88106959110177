import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { authRoutes, publicRoutes, adminRoutes } from "../routes";
import { LOGIN_ROUTE } from "../utils/consts";
import { Context } from "../index";
import Dashboard from "./Dashboard";

const AppRouter = () => {
  const { user } = useContext(Context);

  console.log(user);
  return (
    <Switch>
      {user.isAuth &&
        authRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} exact>
            <Dashboard>
              <Component />
            </Dashboard>
          </Route>
        ))}
      {user.isAuth &&
        adminRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} exact>
            <Dashboard>
              <Component />
            </Dashboard>
          </Route>
        ))}
      {publicRoutes.map(({ path, Component }) => (
        <Route key={path} path={path} component={Component} exact />
      ))}
      <Redirect to={LOGIN_ROUTE} />
      location.reload();
    </Switch>
  );
};

export default AppRouter;
