import { Grid, makeStyles, Paper, TextField } from "@material-ui/core";
import React, { useState } from "react";
import Title from "../components/Title";
import clsx from "clsx";
import StripeContainer from "../components/StripeContainer";
import PaymentForm from "../components/PaymentForm";

const Topup = () => {
  const [amount, setAmount] = useState("");
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeight: {
      height: 300,
    },
  }));
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <Grid item xs={12} md={8} lg={9}>
      <Paper className={fixedHeightPaper}>
        <Title>Topup account by credit card</Title>
        <form className={classes.root} noValidate autoComplete="off">
          <p>
            <TextField
              id="standard-basic"
              label="Eur"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </p>
        </form>
        <StripeContainer amount={amount} />
      </Paper>
      <Paper>
        <PaymentForm />
      </Paper>
    </Grid>
  );
};

export default Topup;
