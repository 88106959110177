import { observer } from "mobx-react-lite";
import { Grid, Paper, makeStyles, Typography } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import HashInfo from "../components/HashInfo";
import Orders from "../components/Orders";
import clsx from "clsx";
import firebase from "../utils/firebase-config";
import "firebase/firestore";
import { Context } from "../index";

const Hash = observer(() => {
  const { id } = useParams();
  const { user } = useContext(Context);
  const [hashData, setHashData] = useState({});

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeight: {
      height: 240,
    },
  }));

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  useEffect(() => {
    const bodyLogin = {
      email: "aleksandr@smspm.com",
      password: "12Sanych",
    };
    const init = {
      body: JSON.stringify(bodyLogin),
      method: "POST",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
    };
    console.log("Test2");
    fetch("https://api-gdn.paas.macrometa.io/_open/auth", init)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Test: " + JSON.stringify(data));
        const token = data.jwt;

        const searchHashData = {
          body: `{"bindVars":{},"query":"FOR doc IN hash FILTER doc._key == \\"${id}\\"   RETURN doc","ttl":0}`,
          method: "POST",
          headers: {
            "content-type": "application/json;charset=UTF-8",
            Authorization: "bearer " + token,
            ttl: 0,
          },
        };

        fetch(
          "https://api-gdn.paas.macrometa.io/_fabric/_system/_api/cursor",
          searchHashData
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log("Data123: " + JSON.stringify(data.result));
            if (data.result.length == 0) {
              console.log("Hash not found");
            } else {
              setHashData(data.result[0]);
            }
          });
      });
  }, []);

  return (
    <Grid container spacing={3}>
      {/* Chart */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper className={fixedHeightPaper}>
          <div>
            <Typography variant="h3" gutterBottom>
              Send SMS via API
            </Typography>
            1. URL:
            https://api.smspm.com/v1/sendsms?toNumber=372958247397&fromNumber=551188&text=test&hash=
            {id} <br />
            <br />
            2. POST request: https://api.smspm.com/v1/sendsms <br />
            Body: <br />
            {"{"}
            <br />
            "toNumber": "380958247397",
            <br />
            "fromNumber": "1188",
            <br /> "text": "This is a test message from SMSPM",
            <br />
            "hash": "e7d8cd-dd54-bf4e-31f7-caf2c813f45" <br />
            {"}"}
          </div>
        </Paper>
      </Grid>
      {/* Recent Deposits */}
      <Grid item xs={12} md={4} lg={3}>
        {console.log("Balance: " + user._user.balance)}
        <Paper className={fixedHeightPaper}>
          <HashInfo
            hashData={hashData}
            hashId={id}
            userBalance={user._user.balance}
          />
        </Paper>
      </Grid>
      {/* Recent Orders */}
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Orders />
        </Paper>
      </Grid>
    </Grid>
  );
});

export default Hash;
