import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Title from "./Title";
//import {customer} from '../http/userAPI'
import { useHistory } from "react-router-dom";
import firebase from "../utils/firebase-config";
import "firebase/firestore";
import { TOPUP_ROUTE, HASH_ROUTE } from "../utils/consts";
import { useContext } from "react";
import { Context } from "../index";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { transferToAccount } from "../http/userAPI";

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});
const Deposits = observer(() => {
  const { user } = useContext(Context);
  const { hash } = useContext(Context);
  const [balance, setBalance] = useState("");
  const [oldUserName, setOldUserName] = useState("");
  const [hashData, setHashData] = useState([]);
  const [transferOpen, setTransferOpen] = useState(false);
  const [transferredAmount, setTransferredAmount] = useState("");
  const classes = useStyles();
  const history = useHistory();

  let hashArray = [];

  useEffect(() => {
    const db = firebase.firestore();
    const bal = db.collection("users").doc(user._user.uid);
    bal.get().then((response) => {
      if (response.exists) {
        setBalance(response.data().balance);
        setOldUserName(response.data().oldUserName);
      } else {
        // Create a new document user._user.uid in collection "users"
        db.collection("users").doc(user._user.uid).set({
          balance: 0,
        });
        setBalance(0);
      }
    });

    /*
    const hashRef = db.collection("hash");
    hashRef
      .where("userId", "==", user._user.uid)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          hashRef.doc().set({
            balance: 0,
            hashName: "Sub account",
            userId: result.user.uid,
          });
          hashArray.push({
            balance: 0,
            hashName: "Sub account",
            userId: result.user.uid,
          });
          console.log("No matching documents.");
        } else {
          console.log(JSON.stringify(snapshot));
          snapshot.forEach((doc) => {
            let tempArray = doc.data();
            tempArray.id = doc.id;
            hashArray.push(tempArray);
            console.log(doc.id, "=>", doc.data());
          });
        }
        setHashData(hashArray);
      });
      */
  }, []);

  const handleClickOpen = () => {
    setTransferOpen(true);
  };

  const handleClose = () => {
    setTransferOpen(false);
  };

  const transferToOldAccount = () => {
    transferToAccount(user._user.uid, transferredAmount).then((response) => {
      console.log(response);
    });
  };

  return (
    <React.Fragment>
      <Title>Your balance</Title>
      <Typography component="p" variant="h4">
        €{balance}
      </Typography>
      {hash._hash.result.map((doc, key) => (
        <Link
          color="primary"
          key={doc._key}
          onClick={() => history.push(HASH_ROUTE + "/" + doc._key)}
        >
          {doc.name} €{doc.balance}
        </Link>
      ))}
      <Link color="primary" onClick={handleClickOpen}>
        Transfer to your old account
      </Link>
      <div>
        <Link color="primary" onClick={() => history.push(TOPUP_ROUTE)}>
          Topup account
        </Link>
      </div>
      <div>
        <Dialog
          open={transferOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Transfer to your old account {oldUserName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              To subscribe to this website, please enter your email address
              here. We will send updates occasionally.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Amount"
              type="email"
              fullWidth
              value={transferredAmount}
              onChange={(e) => setTransferredAmount(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={transferToOldAccount} color="primary">
              Transfer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
});

export default Deposits;
