import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import firebase from "../utils/firebase-config";
import "firebase/firestore";
import "firebase/database";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SettingsIcon from "@material-ui/icons/Settings";
import Title from "./Title";
import {
  Button,
  IconButton,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { addKV, getPricesKV } from "../http/userAPI";
import axios from "axios";
import { observable } from "mobx";

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

const LastSms = observer((props) => {
  const classes = useStyles();
  const [panelData, setPanelData] = useState([]);
  const [pricesData, setPricesData] = useState([]);
  const [fishData, setFishData] = useState({});
  const [smsApiData, setSmsApiData] = useState({});
  const [detailsKey, setDetailsKey] = useState(0);
  const [panelPrice, setPanelPrice] = useState(0);
  const [routeId, setRouteId] = useState("");
  const [updatedRow, setUpdatedRow] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  var database = firebase.database();

  useEffect(() => {
    loadPrices();
    if (typeof props.hashData._key == "undefined") {
    } else {
      let ref = database.ref();
      ref.child("priceListPanel").on("value", (snapshot) => {
        const items = [];
        snapshot.forEach((doc) => {
          let obj = {};
          obj = doc.val();
          items.push({ [doc.key]: obj });
        });
        setPanelData(items);
      });

      ref.child("priceListFish").on("value", (snapshot) => {
        // setFishData(JSON.parse(snapshot));
        setFishData(snapshot.val());
      });

      ref.child("priceListSMSApi").on("value", (snapshot) => {
        // console.log("Fish snapshot: " + snapshot.val());
        // setFishData(JSON.parse(snapshot));
        setSmsApiData(snapshot.val());
      });
    }
  }, [props]);

  const loadPrices = () => {
    getPricesKV().then((response) => {
      console.log("PricesKV:" + JSON.stringify(response));
      setPricesData(response);
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updatePanelPricelist = () => {
    axios
      .get(
        "https://us-central1-my-new-project-1344.cloudfunctions.net/csv_price_parser?route=panel"
      )
      .then(() => {
        setSnackbarMessage("Panel prices are updated");
      });
  };

  const selectProvider = async (provider) => {
    console.log(
      "Updated row: " +
        JSON.stringify(updatedRow) +
        ", provider: " +
        JSON.stringify(provider) +
        ", price: " +
        panelPrice
    );
    let obj = updatedRow;
    obj.metadata[provider] = panelPrice;
    obj.metadata.default = provider;
    console.log("Updated row: " + JSON.stringify(obj));

    const response = await addKV(obj);
    console.log("Response after clicking button: " + JSON.stringify(response));
    if (response.message == "OK") {
      console.log("true");
      setSnackbarMessage("Route is updated");
      setOpenSnackbar(true);
      setAnchorEl(null);
    }
  };

  return (
    <React.Fragment>
      <Title>PriceList</Title>
      <Button
        variant="contained"
        color="primary"
        onClick={updatePanelPricelist}
      >
        Update Panel pricelist
      </Button>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Carrier</TableCell>
            <TableCell align="right">List of routes</TableCell>
            <TableCell align="right">Route</TableCell>
            <TableCell align="right">Settings</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pricesData.map((row, key) => (
            <TableRow
              key={key}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">
                {Object.keys(row.metadata).map((row2, key) => (
                  <>
                    {row2}({row.metadata[row2]}),{" "}
                  </>
                ))}
              </TableCell>
              <TableCell align="right">
                {row.metadata?.default} {row.metadata?.[row.metadata.default]}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={(e) => {
                    // setRouteId(row[Object.keys(row)[0]][row2].id);
                    // setPanelPrice(row[Object.keys(row)[0]][row2].price);
                    console.log("Alert: " + JSON.stringify(row));
                    setUpdatedRow(row);
                    setRouteId(1234);
                    setPanelPrice(row.metadata?.[row.metadata.default]);
                    handleClick(e);
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </TableCell>

              <TableCell align="right">
                {/*JSON.stringify(hash._hash ) */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Country</TableCell>
            <TableCell>Operators</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {panelData.map((row, key) => (
            <TableRow key={key}>
              <TableCell>{Object.keys(row)[0]}</TableCell>
              <TableCell>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Operator</TableCell>
                      <TableCell>Panel</TableCell>
                      <TableCell>Fish</TableCell>
                      <TableCell>SMSApi</TableCell>
                      <TableCell align="right">Route</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {panelData[detailsKey]
                      ? Object.keys(row[Object.keys(row)[0]]).map(
                          (row2, key2) => (
                            <TableRow key={key2}>
                              <TableCell>{row2}</TableCell>
                              <TableCell>
                                {row[Object.keys(row)[0]][row2].price}
                              </TableCell>
                              <TableCell>
                                {fishData.hasOwnProperty(
                                  Object.keys(row)[0]
                                ) ? (
                                  fishData[Object.keys(row)[0]][row2] <
                                  row[Object.keys(row)[0]][row2] ? (
                                    fishData[Object.keys(row)[0]][row2]
                                  ) : (
                                    <p style={{ color: "red" }}>
                                      {fishData[Object.keys(row)[0]][row2]}
                                    </p>
                                  )
                                ) : (
                                  "Not defined"
                                )}
                              </TableCell>
                              <TableCell>
                                {smsApiData.hasOwnProperty(
                                  Object.keys(row)[0]
                                ) ? (
                                  smsApiData[Object.keys(row)[0]][row2] <
                                  row[Object.keys(row)[0]][row2] ? (
                                    smsApiData[Object.keys(row)[0]][row2]
                                  ) : (
                                    <p style={{ color: "red" }}>
                                      {smsApiData[Object.keys(row)[0]][row2]}
                                    </p>
                                  )
                                ) : (
                                  "Not defined"
                                )}
                              </TableCell>
                              <TableCell align="right">
                                <Button
                                  color="primary"
                                  onClick={(e) => {
                                    setRouteId(
                                      row[Object.keys(row)[0]][row2].id
                                    );
                                    setPanelPrice(
                                      row[Object.keys(row)[0]][row2].price
                                    );
                                    handleClick(e);
                                  }}
                                >
                                  {row[Object.keys(row)[0]][row2].route}
                                </Button>
                              </TableCell>
                            </TableRow>
                          )
                        )
                      : ""}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <p>
          <Input
            id="standard-basic"
            label="Price"
            value={panelPrice}
            onChange={(e) => setPanelPrice(e.target.value)}
            style={{
              width: 100,
              marginLeft: 10,
              marginRight: 10,
            }}
            startAdornment={<InputAdornment position="start">€</InputAdornment>}
          />
        </p>
        <MenuItem onClick={() => selectProvider("F1")}>F1</MenuItem>
        <MenuItem onClick={() => selectProvider("S1")}>S1</MenuItem>
        <MenuItem onClick={() => selectProvider("I1")}>I1</MenuItem>
      </Menu>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
            ></IconButton>
          </React.Fragment>
        }
      />
      <div className={classes.seeMore}>
        <Link color="primary" href="#" onClick={preventDefault}>
          See more
        </Link>
      </div>
    </React.Fragment>
  );
});

export default LastSms;
