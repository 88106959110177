import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import firebase from "../utils/firebase-config";
import "firebase/firestore";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "./Title";

// import { allPayments } from "../http/userAPI";

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

const Orders = observer(() => {
  const { user } = useContext(Context);
  const classes = useStyles();
  const [ordersData, setOrdersData] = useState([]);
  const db = firebase.firestore();

  useEffect(() => {
    // allPayments().then((data) => setOrdersData(data));
    db.collection("payments")
      .doc(user._user.uid)
      .collection("stripe")
      .get()
      .then((query) => {
        setOrdersData(query.docs);
        // query.docs.map((doc) => console.log(doc.id + " " + doc.data()));
      });

    // query.docs.map((doc) => console.log(doc.id + " " + doc.data()));

    //setOrdersData(query);
    // query.docs.map((doc) => console.log(doc.data()));
    // console.log("Query:" + JSON.stringify(query));
  }, []);
  const temp = ordersData[0];
  console.log("All payments: " + JSON.stringify(temp));
  //console.log(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(ordersData[0].createdAt));

  let test = db
    .collection("payments")
    .doc(user._user.uid)
    .collection("stripe")
    .get();
  //setOrdersData(test);

  return (
    <React.Fragment>
      <Title>Recent Orders</Title>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="right">Sale Amount</TableCell>
            <TableCell>Payment Method</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ordersData.map((row) => (
            <TableRow key={row.data().id}>
              {console.log(row.data())}
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.data().id}</TableCell>
              <TableCell align="right">{row.data().amount / 100}</TableCell>
              <TableCell>
                <Link
                  color="primary"
                  href={row.data().receipt_url}
                  onClick={preventDefault}
                >
                  Invoice
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Link color="primary" href="#" onClick={preventDefault}>
          See more orders
        </Link>
      </div>
    </React.Fragment>
  );
});

export default Orders;
