import { observer } from "mobx-react-lite";
import React, { useState, useContext, useEffect } from "react";
import { getPrices, changeDefaultRoute } from "../http/userAPI";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Context } from "../index";

const Prices = observer(() => {
  const { hash } = useContext(Context);
  const [pricesData, setPricesData] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  // const [carrierKey, setCarrierKey] = useState("");
  const [pricesObject, setPricesObject] = useState({});
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [snackOpen, setSnackOpen] = useState(true);

  useEffect(() => {
    loadPrices();
    console.log("Hash fom proces:" + JSON.stringify(hash));
  }, []);

  const loadPrices = () => {
    getPrices(hash._hash.result[0]._key).then((response) => {
      console.log("Prices:" + JSON.stringify(response));
      setPricesData(response);
    });
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuClick = (defaultValue) => {
    console.log("defaultValue: " + defaultValue);
    console.log("defaultValue p: " + pricesObject.name);
    changeDefaultRoute(
      hash._hash.result[0]._key,
      pricesObject.name,
      defaultValue
    ).then((response) => {
      console.log(response);
      console.log(response.status);
      if ((response.status = "changed")) {
        setMenuOpen(false);
      }
    });
  };

  function SimpleMenu() {
    console.log("Simple Menu: ", JSON.stringify(pricesObject));
    return typeof pricesObject.metadata != "undefined" ? (
      <Menu open={menuOpen} anchorEl={menuAnchorEl} onClose={handleMenuClose}>
        {Object.keys(pricesObject.metadata).map((row2, key) =>
          row2 !== "default" ? (
            <MenuItem key={key} onClick={() => handleMenuClick(row2, "test")}>
              <ArrowRightIcon />
              {console.log("Row2: " + row2)}
              {row2} ({pricesObject.metadata[row2]}€)
            </MenuItem>
          ) : (
            ""
          )
        )}
      </Menu>
    ) : (
      ""
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Carrier</TableCell>
              <TableCell align="right">List of routes</TableCell>
              <TableCell align="right">Route</TableCell>
              <TableCell align="right">Settings</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pricesData.map((row, key) => (
              <TableRow
                key={key}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">
                  {Object.keys(row.metadata).map((row2, key) => (
                    <>
                      {row2}({row.metadata[row2]}),{" "}
                    </>
                  ))}
                </TableCell>
                <TableCell align="right">
                  {row.metadata?.default} {row.metadata?.[row.metadata.default]}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={(event) => {
                      setMenuOpen(true);

                      setMenuAnchorEl(event.currentTarget);
                      setPricesObject(row);
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </TableCell>

                <TableCell align="right">
                  {/*JSON.stringify(hash._hash ) */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <SimpleMenu open={menuOpen} />
      </TableContainer>
    </>
  );
});

export default Prices;
