import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import firebase from "../utils/firebase-config";
import "firebase/firestore";
import "firebase/database";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "./Title";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

// import { allPayments } from "../http/userAPI";

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

const LastSms = observer((props) => {
  const { user } = useContext(Context);
  const classes = useStyles();
  const [ordersData, setOrdersData] = useState([]);
  const [open, setOpen] = useState(false);
  const [smsData, setSmsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [detailsKey, setDetailsKey] = useState(0);
  const [smsDetails, setSmsDetails] = useState({});
  const db = firebase.firestore();
  var database = firebase.database();

  useEffect(() => {
    console.log("props.hash " + JSON.stringify(props));
    if (typeof props.hashData._key == "undefined") {
    } else {
      let ref = database.ref();
      console.log("props.hash " + JSON.stringify(props));
      ref
        .child("sms")
        .orderByChild("hash")
        .equalTo(props.hashData._key)
        .limitToLast(10)
        .on("value", (snapshot) => {
          const items = [];
          snapshot.forEach((doc) => {
            let obj = {};
            console.log("Key: ", snapshot.key, doc.key);
            obj = doc.val();
            obj.id = doc.key;
            let docValue = doc.val;
            items.push(obj);
          });
          console.log("Ites: " + JSON.stringify(items));
          setSmsData(items);
          setLoading(false);

          const data = snapshot.val();
          // console.log("SMS: " + JSON.stringify(data));
        });
    }
  }, [props]);
  const temp = ordersData[0];
  //console.log(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(ordersData[0].createdAt));

  const handleDetailsOpen = () => {
    setOpen(true);
  };

  const handleDetailsClose = () => {
    setOpen(false);
  };

  function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;

    console.log("Props: ", props);
    console.log("Props key: ", props.key);
    console.log("State: ", JSON.stringify(smsDetails));
    console.log("Details: ", JSON.stringify(smsData[detailsKey]));

    const handleClose = () => {
      onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
      onClose(value);
    };

    const dateConvertor = (value) => {
      var date = new Date(value);
      var formattedTime = date.toLocaleString();
      return formattedTime;
    };

    return (
      <Dialog
        onClose={handleDetailsClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        {console.log("Test data : " + smsData[detailsKey])}
        <DialogTitle id="simple-dialog-title">
          SMS ID: {smsData[detailsKey] ? smsData[detailsKey].id : ""}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b>Status: </b>{" "}
            {smsData[detailsKey] ? smsData[detailsKey].status : ""}
          </DialogContentText>

          <DialogContentText>
            <b>Number: </b>{" "}
            {smsData[detailsKey] ? smsData[detailsKey].toNumber : ""}
          </DialogContentText>
          <DialogContentText>
            <b>From: </b>{" "}
            {smsData[detailsKey] ? smsData[detailsKey].fromNumber : ""}
          </DialogContentText>
          <DialogContentText>
            <b>Text: </b> {smsData[detailsKey] ? smsData[detailsKey].text : ""}
          </DialogContentText>
          <DialogContentText>
            <b>Amount of messages: </b>{" "}
            {smsData[detailsKey] ? smsData[detailsKey].sms_count : ""}
          </DialogContentText>
          <DialogContentText>
            <b>Country: </b>{" "}
            {smsData[detailsKey] ? smsData[detailsKey].country : ""}
          </DialogContentText>
          <DialogContentText>
            <b>Carrier: </b>{" "}
            {smsData[detailsKey] ? smsData[detailsKey].carrier : ""}
          </DialogContentText>
          <DialogContentText>
            <b>Route: </b>{" "}
            {smsData[detailsKey] ? smsData[detailsKey].provider : ""}
          </DialogContentText>
          <DialogContentText>
            <b>Price: </b>{" "}
            {smsData[detailsKey] ? smsData[detailsKey].price : ""}
          </DialogContentText>
          <DialogContentText>
            <b>History: </b>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {smsData[detailsKey]
                  ? smsData[detailsKey].history.map((row, key) => (
                      <TableRow key={key}>
                        {console.log(row)}
                        <TableCell>{row.status}</TableCell>
                        <TableCell>{dateConvertor(row.time)}</TableCell>
                      </TableRow>
                    ))
                  : ""}
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <React.Fragment>
      <Title>Recent SMS</Title>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>SMS ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Number</TableCell>
            <TableCell>Sale Amount</TableCell>
            <TableCell>Text</TableCell>
            <TableCell align="right">Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {smsData.map((row, key) => (
            <TableRow key={key}>
              {console.log(row)}
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.toNumber}</TableCell>
              <TableCell>{row.fromNumber}</TableCell>
              <TableCell>{row.text}</TableCell>
              <TableCell align="right">
                <Button
                  value={row.id}
                  href="#text-buttons"
                  color="primary"
                  onClick={() => {
                    console.log("KKey: ", key);
                    setDetailsKey(key);
                    setOpen(true);
                  }}
                >
                  Details
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {loading ? (
        "loading..."
      ) : (
        <SimpleDialog open={open} onClose={handleDetailsClose} />
      )}
      <div className={classes.seeMore}>
        <Link color="primary" href="#" onClick={preventDefault}>
          See more
        </Link>
      </div>
    </React.Fragment>
  );
});

export default LastSms;
