import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePaymentFormOldAccount from "./StripePaymentFormOldAccount";

//const PUBLIC_KEY = process.env.STRIPE_SECRET_TEST;
//const PUBLIC_KEY =
//  "pk_test_51IRK93KS9226KED2yQtr5GxUugj0j9m9KlLRk0zgTtVIsDUCmZdXfGJPr7jm4iljkeoG1k3KKDSptvR2QrY9sXBx008LQ5ZqrN";

const PUBLIC_KEY =
  "pk_live_51IRK93KS9226KED29J2bzUiGQa2azqbJr0DspevafHEySVMzTuI4YKvg8ACY5aHFdNFd5zsLEqI9SqSZZIa6oCeu000IXlICoJ";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const StripeContainer = (props) => {
  return (
    <Elements stripe={stripeTestPromise}>
      <StripePaymentFormOldAccount
        amount={props.amount}
        userName={props.userName}
      />
    </Elements>
  );
};

export default StripeContainer;
