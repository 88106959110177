import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import Title from "./Title";
import { payment } from "../http/userAPI";

const StripePaymentForm = (props) => {
  const [success, setSuccess] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [paymentResult, setPaymentResult] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenBackdrop(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      console.log("paymentMethod : " + JSON.stringify(paymentMethod));
      try {
        const { id } = paymentMethod;
        const stripeAmount = props.amount;
        const userName = props.userName;
        const response = await payment(stripeAmount, id, userName);
        console.log(
          "Response after clicking button: " + JSON.stringify(response)
        );
        setPaymentResult(response);
        setOpenBackdrop(false);
        setOpenSnackbar(true);

        // redirect to Dashboard
        window.setTimeout(function () {
          window.location.href = "/";
        }, 5000);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log(error.message);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <CardElement />
        </fieldset>
        <p>
          <Button type="submit" fullWidth variant="contained" color="primary">
            Topup
          </Button>
        </p>
      </form>
      {paymentResult.success ? "Payment completed" : ""}
      {!paymentResult.success ? paymentResult.error : ""}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Your payment is completed!"
      />
      <Backdrop open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default StripePaymentForm;
