import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import StripeContainerOldAccount from "../components/StripeContainerOldAccount";
import { useParams } from "react-router-dom";
import SalesIQ from "../components/SalesIQ";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://app.smspm.com/">
        iSMS Solutions OU
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Checkout() {
  const [amount, setAmount] = useState("");
  const classes = useStyles();
  const { userName } = useParams();

  //const query = new URLSearchParams(this.props.location.search);
  //const token = query.get("username");
  console.log("Username: " + userName); //123

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            iSMS Solutions OU
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography>

          <Typography>
            Topup account by credit card for user {userName} via Stripe.
            Commission fee will be deducted.
            <Alert severity="warning">
              This payment will be reviewed manually. If we find your account or
              payment suspicious, we might ask for additional information before
              adding money to your account.
            </Alert>
          </Typography>
          <form className={classes.root} noValidate autoComplete="off">
            <p>
              <TextField
                id="standard-basic"
                label="Eur"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </p>
          </form>
          <p>
            <StripeContainerOldAccount amount={amount} userName={userName} />
          </p>
        </Paper>
        <Copyright />
        <SalesIQ />
      </main>
    </React.Fragment>
  );
}
