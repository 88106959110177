import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Backdrop,
  Button,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import Title from "./Title";
import { payment, topupOldAccount } from "../http/userAPI";
import "react-toastify/dist/ReactToastify.css";

const StripePaymentForm = (props) => {
  const [success, setSuccess] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [paymentResult, setPaymentResult] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenBackdrop(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      console.log("paymentMethod : " + JSON.stringify(paymentMethod));
      try {
        const { id } = paymentMethod;
        const stripeAmount = props.amount;
        const userName = props.userName;
        const response = await topupOldAccount(
          stripeAmount,
          id,
          userName,
          "payment_method_id"
        );
        console.log(
          "Response after clicking button: " + JSON.stringify(response)
        );

        if (response.error) {
          // Show error from server on payment form
        } else if (response.requires_action) {
          // Use Stripe.js to handle required card action
          stripe
            .handleCardAction(response.payment_intent_client_secret)
            .then(async (result) => {
              const response3ds = await topupOldAccount(
                stripeAmount,
                result.paymentIntent.id,
                userName,
                "payment_intent_id"
              );
              console.log(
                JSON.stringify("response3ds: " + JSON.stringify(response3ds))
              );
              if (response3ds.success) {
                setPaymentResult(response);
                setOpenBackdrop(false);
                setOpenSnackbar(true);
                //  redirect to panel
                window.setTimeout(function () {
                  window.location.href = "https://panel.smspoint.ee/";
                }, 5000);
              } else {
                console.log(JSON.stringify(response3ds.message));
              }
            });
        } else {
          // Show success message
          setPaymentResult(response);
          setOpenBackdrop(false);
          setOpenSnackbar(true);

          //  redirect to panel
          window.setTimeout(function () {
            window.location.href = "https://panel.smspoint.ee/";
          }, 5000);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log(error.message);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <CardElement />
        </fieldset>
        <p>
          <Button type="submit" fullWidth variant="contained" color="primary">
            Topup
          </Button>
        </p>
      </form>
      {paymentResult.success ? "Payment completed" : ""}
      {!paymentResult.success ? paymentResult.error : ""}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Your payment is completed!"
      />
      <Backdrop open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default StripePaymentForm;
