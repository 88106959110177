import axios from "axios";
import firebase from "../utils/firebase-config";
import jwt_decode from "jwt-decode";

const $host = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const $authHost = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const authInterceptor = (config) => {
  // config.headers.authorization = `Bearer ${localStorage.getItem("token")}`;

  firebase
    .auth()
    .currentUser.getIdTokenResult()
    .then((response) => {
      console.log("Access token: " + JSON.stringify(response.token));
      config.headers.authorization = "Bearer " + response.token;
      //return config;
    });

  return config;
};

$authHost.interceptors.request.use(authInterceptor);

export { $host, $authHost };
