import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { PANEL_ROUTE } from "../utils/consts";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import firebase from "../utils/firebase-config";
import "firebase/firestore";
import uuid from "react-uuid";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://app.smspm.com/">
        iSMS Solutions OU
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    // backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundImage: "url(https://app.smspm.com/login_bg.jpeg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignInSide = observer(() => {
  const { user } = useContext(Context);
  const [phone, setPhone] = useState("");
  const [pincode, setPincode] = useState("");
  const [showOTP, setShowOTP] = useState(false);

  const classes = useStyles();

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function () {
          this.onSignInSubmit();
        },
        defaultCountry: "ET",
      }
    );
  };

  const onSubmitOtp = () => {
    let optConfirm = window.confirmationResult;
    optConfirm
      .confirm(pincode)
      .then(function (result) {
        // User signed in successfully.

        user.setUser(result.user);
        user.setIsAuth(true);

        const db = firebase.firestore();
        const usersRef = db.collection("users").doc(result.user.uid);
        usersRef.get().then((docSnapshot) => {
          if (!docSnapshot.exists) {
            usersRef.set({ balance: 0, minBalance: 0 });
            user.balance = 0;
            user.minBalance = 0;
          }
        });

        const bodyLogin = {
          email: "aleksandr@smspm.com",
          password: "12Sanych",
        };
        const init = {
          body: JSON.stringify(bodyLogin),
          method: "POST",
          headers: {
            "content-type": "application/json;charset=UTF-8",
          },
        };
        fetch("https://api-gdn.paas.macrometa.io/_open/auth", init)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            const token = data.jwt;

            const searchHashData = {
              body: `{"bindVars":{},"query":"FOR doc IN hash FILTER doc.userId == \\"${result.user.uid}\\"   RETURN doc","ttl":0}`,
              method: "POST",
              headers: {
                "content-type": "application/json;charset=UTF-8",
                Authorization: "bearer " + token,
                ttl: 0,
              },
            };

            fetch(
              "https://api-gdn.paas.macrometa.io/_fabric/_system/_api/cursor",
              searchHashData
            )
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                if (data.result.length == 0) {
                  const init = {
                    // body: `{
                    //  query: INSERT {_key: "${result.user.uid}", enabled: true, balance: 0.1, minBalance: 0, sender:{["${result.user.phoneNumber}"]} IN hash }`,
                    //  body: '{"bindVars":{},"query":"FOR doc IN hash RETURN doc","ttl":0}',
                    body: `{"bindVars":{},"query":"INSERT {_key: \\"${uuid()}\\", userId: \\"${
                      result.user.uid
                    }\\", name: \\"Sub account\\", enabled: true, balance: 0.1, minBalance: 0, sender: [\\"${
                      result.user.phoneNumber
                    }\\"]} IN hash","ttl":0}`,
                    // body: '{"query":"INSERT {\n    _key: \\"GilbertoGil1\\",\n    firstName: \\"Gilberto\\",\n    name: \\"Gil\\",\n    city: \\"Fortalezza\\"\n} IN hash"}',
                    method: "POST",
                    headers: {
                      "content-type": "application/json;charset=UTF-8",
                      Authorization: "bearer " + token,
                      ttl: 0,
                    },
                  };
                  fetch(
                    "https://api-gdn.paas.macrometa.io/_fabric/_system/_api/cursor",
                    init
                  )
                    .then((response) => {
                      return response.json();
                    })
                    .then((data) => {
                      request.onsuccess = function () {
                        // Start a new transaction
                        var db = request.result;
                        var tx = db.transaction("smspmHash", "readwrite");
                        var store = tx.objectStore("smspmHash");
                        console.log("Data1: " + data.result[0]);
                        store.put(data.result[0]);
                      };
                      request.onerror = function (event) {
                        alert(JSON.stringify(event) + " 1");
                      };
                    });
                } else {
                  var request = indexedDB.open("smspmDb", 2);
                  request.onupgradeneeded = function () {
                    var db = request.result;
                    db.createObjectStore("smspmHash", {
                      keyPath: "_key",
                    });
                    db.createObjectStore("SelectedKey", {
                      keyPath: "id",
                    });
                  };

                  request.onsuccess = function () {
                    // Start a new transaction
                    var db = request.result;
                    var tx = db.transaction("smspmHash", "readwrite");
                    var store = tx.objectStore("smspmHash");
                    // store.put(data.result[0]);
                    for (let i = 0; i < data.result.length; i++) {
                      console.log("Data2: " + data.result[i]);
                      store.put(data.result[i]);
                    }
                    var tx1 = db.transaction("SelectedKey", "readwrite");
                    var store1 = tx1.objectStore("SelectedKey");
                    store1.put({ id: 1, selected: data.result[0]._key });

                    // Close the db when the transaction is done
                    tx.oncomplete = function () {
                      db.close();
                    };
                  };
                  request.onerror = function (event) {
                    // Todo: Throws error here
                    /*
                    alert(
                      JSON.stringify(event, [
                        "message",
                        "arguments",
                        "type",
                        "name",
                      ])
                    );
                    */
                  };
                }
                localStorage.setItem(
                  "token",
                  result.user.toJSON().stsTokenManager.accessToken
                );
                location.replace(PANEL_ROUTE);
              });
          });

        /*
        const hashRef = db.collection("hash");
        hashRef
          .where("userId", "==", result.user.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.empty) {
              hashRef.doc().set({
                balance: 0,
                hashName: "Sub account",
                userId: result.user.uid,
              });
              alert("No matching documents.");
            } else {
              snapshot.forEach((doc) => {
                alert(doc.id, "=>", doc.data());
              });
            }
          });
*/
      })
      .catch(function (error) {
        alert(error + " 5");
        alert("Incorrect OTP");
      });
  };

  const onSignInSubmit = () => {
    setUpRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phone, appVerifier)
      .then(function (confirmationResult) {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setShowOTP(true);
      })
      .catch(function (error) {
        alert(error + " 1");
      });
  };

  function ShowOTPField() {
    return (
      <>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="pincode"
          label="pincode"
          name="pincode"
          autoComplete="pincode"
          autoFocus
          value={pincode}
          onChange={(e) => setPincode(e.target.value)}
        />

        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={onSubmitOtp}
        >
          Sign In
        </Button>
      </>
    );
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Box m={3}>
            <img src={"https://app.smspm.com/smspm_logo_200.png"} />
          </Box>
          <Typography component="h1" variant="h5">
            Sign in with phone number
          </Typography>
          <form className={classes.form} noValidate>
            <div id="recaptcha-container"></div>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Phone number"
              name="phone"
              autoComplete="phone"
              autoFocus
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />

            {!showOTP ? (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={onSignInSubmit}
              >
                Sign In
              </Button>
            ) : (
              ""
            )}

            {showOTP ? <ShowOTPField /> : ""}

            {/*
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href={REGISTRATION_ROUTE} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
              */}
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
});

export default SignInSide;
