import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Title from "./Title";
//import {customer} from '../http/userAPI'
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import firebase from "../utils/firebase-config";
import "firebase/firestore";
import { TOPUP_ROUTE, HASH_ROUTE, PANEL_ROUTE } from "../utils/consts";
import { useContext } from "react";
import { Context } from "../index";
import { transferBetweenAccounts } from "../http/userAPI";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});
const HashInfo = observer((props) => {
  const { user } = useContext(Context);
  const [balance, setBalance] = useState("");
  const [hashData, setHashData] = useState([]);
  const [transferedAmount, setTransferedAmount] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const [openTransfer, setOpenTransfer] = React.useState(false);

  let hashArray = [];

  useEffect(() => {
    /*
    var open = indexedDB.open("smspmDb", 3);
    open.onsuccess = function () {
      // Start a new transaction
      var db = open.result;

      var tx1 = db.transaction("smspmHash", "readwrite");
      var store1 = tx1.objectStore("smspmHash");
      var dbResults = store1.get();
      dbResults.onsuccess = function () {
        console.log(
          "DBout: " + user._user.uid,
          JSON.stringify(dbResults.result)
        );
        setHashData(dbResults.result);
      };
    };
*/
  }, []);

  const handleTransferClickOpen = () => {
    setOpenTransfer(true);
    const db = firebase.firestore();
    const bal = db.collection("users").doc(user._user.uid);
    console.log(user._user.uid);
    bal.get().then((response) => {
      console.log(response.data());
      if (response.exists) {
        setBalance(response.data().balance);
      } else {
        setBalance(0);
      }
    });
  };

  const handleTransferClose = () => {
    setOpenTransfer(false);
  };

  const handleTransferProcess = () => {
    transferBetweenAccounts(
      user._user.uid,
      props.hashId,
      transferedAmount
    ).then((response) => {
      console.log(response);
    });
  };

  const handleDeleteSubAccount = () => {
    const db = firebase.firestore();
    const hashId = props.hashId;
    db.collection("hash")
      .doc(hashId)
      .delete()
      .then(() => {
        history.push(PANEL_ROUTE);
      });
  };

  return (
    <React.Fragment>
      <Typography component="p" variant="h6">
        {props.hashData.name}
        <EditIcon onClick={handleTransferClickOpen} />
        <DeleteIcon onClick={handleDeleteSubAccount} />
      </Typography>
      <Typography component="p" variant="h4">
        €{props.hashData.balance}
      </Typography>

      <Typography color="textSecondary" className={classes.depositContext}>
        ID: {props.hashId}
      </Typography>
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleTransferClickOpen}
        >
          Topup sub account
        </Button>

        <Dialog
          open={openTransfer}
          onClose={handleTransferClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Topup this sub account
          </DialogTitle>
          <DialogContent>
            <InputLabel htmlFor="standard-adornment-amount">
              Maximum amount: €{balance}
            </InputLabel>
            <Input
              id="standard-adornment-amount"
              value={transferedAmount}
              onChange={(e) => setTransferedAmount(e.target.value)}
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleTransferClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleTransferProcess} color="primary">
              Transfer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
});

export default HashInfo;
