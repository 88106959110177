import { makeAutoObservable } from "mobx";

export default class UserStore {
  constructor() {
    this._isAuth = false;
    this._user = {};
    this._token = {};
    this._role = {};
    this._hash = [];
    this._defaultHash = {};
    makeAutoObservable(this);
  }

  setIsAuth(bool) {
    this._isAuth = bool;
  }
  setUser(user) {
    this._user = user;
  }
  setToken(token) {
    this._token = token;
  }
  setRole(role) {
    this._role = role;
  }
  setHash(hash) {
    this._hash = hash;
  }
  setDefaultHash(hash) {
    this._hash = hash;
  }

  get isAuth() {
    return this._isAuth;
  }
  get user() {
    return this._user;
  }
  get token() {
    return this._token;
  }
  get role() {
    return this._role;
  }
  get hash() {
    return this._hash;
  }
  get defaultHash() {
    return this._defaultHash;
  }
}
