export const ADMIN_ROUTE = "/admin";
export const LOGIN_ROUTE = "/login";
export const REGISTRATION_ROUTE = "/signup";
export const PANEL_ROUTE = "/";
export const TOPUP_ROUTE = "/topup";
export const HASH_ROUTE = "/hash";
export const SENDSMS_ROUTE = "/sendsms";
export const ADMIN_SMS_ROUTE = "/admin/sms";
export const ADMIN_PRICELIST_ROUTE = "/admin/pricelist";
export const TOPUP_PANEL_ROUTE = "/topup_panel";
export const SWED_RETURN_ROUTE = "/swed_return";
export const MIGRATE_ROUTE = "/migrate";
export const DEVICES_ROUTE = "/devices";
export const PRICES_ROUTE = "/prices";
