import Admin from "./pages/Admin";
import Login from "./pages/Login";
import Panel from "./pages/Panel";
import Devices from "./pages/Devices";
import Prices from "./pages/Prices";
import Topup from "./pages/Topup";
import Hash from "./pages/Hash";
import SendSms from "./pages/SendSms";
import AdminSMS from "./pages/admin/Sms";
import AdminPricelist from "./pages/admin/Pricelist";
import TopupPanel from "./pages/TopupPanel";
import SwedReturn from "./pages/SwedReturn";
import Migrate from "./pages/Migrate";
import {
  ADMIN_ROUTE,
  LOGIN_ROUTE,
  PANEL_ROUTE,
  TOPUP_ROUTE,
  HASH_ROUTE,
  SENDSMS_ROUTE,
  ADMIN_SMS_ROUTE,
  ADMIN_PRICELIST_ROUTE,
  TOPUP_PANEL_ROUTE,
  SWED_RETURN_ROUTE,
  MIGRATE_ROUTE,
  DEVICES_ROUTE,
  PRICES_ROUTE,
} from "./utils/consts";

export const adminRoutes = [
  {
    path: ADMIN_SMS_ROUTE,
    Component: AdminSMS,
  },
  {
    path: ADMIN_PRICELIST_ROUTE,
    Component: AdminPricelist,
  },
];

export const authRoutes = [
  {
    path: ADMIN_ROUTE,
    Component: Admin,
  },
  {
    path: PANEL_ROUTE,
    Component: Panel,
  },
  {
    path: DEVICES_ROUTE,
    Component: Devices,
  },
  {
    path: PRICES_ROUTE,
    Component: Prices,
  },
  {
    path: TOPUP_ROUTE,
    Component: Topup,
  },
  {
    path: HASH_ROUTE + "/:id",
    Component: Hash,
  },
  {
    path: SENDSMS_ROUTE,
    Component: SendSms,
  },
  {
    path: MIGRATE_ROUTE + "/:oldUserName/:oldHash",
    Component: Migrate,
  },
];

export const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    Component: Login,
  },
  {
    path: TOPUP_PANEL_ROUTE + "/:userName",
    Component: TopupPanel,
  },
  {
    path: SWED_RETURN_ROUTE,
    Component: SwedReturn,
  },
];
