import React from "react";
import { useEffect } from "react";

//Zoho Sales Iq Script:
const useScript = (url, widgetCode) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");

    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;

    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default function SalesIQ() {
  return (
    <React.Fragment>
      {useScript(
        "https://salesiq.zoho.com/widget",
        "ca6eb60aab09000f04359fbe82c922818f849b73eff2eab78ac10371c8b05d9b342df61b5a17b0dbe05f1ec3ad83e4ed"
      )}
    </React.Fragment>
  );
}
