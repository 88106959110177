import { observer } from "mobx-react-lite";
import { Button, Grid, makeStyles, Paper } from "@material-ui/core";
import React, { useState } from "react";
import Title from "../components/Title";
import clsx from "clsx";
import StripeContainer from "../components/StripeContainer";
import PaymentForm from "../components/PaymentForm";
import { useParams } from "react-router-dom";
import { migrateOldAccount } from "../http/userAPI";
import { useContext } from "react";
import { Context } from "../index";

const Migrate = observer((props) => {
  const { user } = useContext(Context);
  const { oldUserName } = useParams();
  const { oldHash } = useParams();
  const [amount, setAmount] = useState("");
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeight: {
      height: 240,
    },
  }));
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const handleMigrate = () => {
    migrateOldAccount(user._user.uid, oldUserName, oldHash).then((response) => {
      console.log(response);
    });
  };

  return (
    <Grid item xs={12} md={8} lg={9}>
      <Paper className={fixedHeightPaper}>
        <Title>
          Migrate user: {oldUserName} {oldHash}
        </Title>
        <Button href="#text-buttons" color="primary" onClick={handleMigrate}>
          Migrate
        </Button>
      </Paper>
    </Grid>
  );
});

export default Migrate;
