import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MessageIcon from "@material-ui/icons/Message";
import PhonelinkSetup from "@material-ui/icons/PhonelinkSetup";
import PeopleIcon from "@material-ui/icons/People";
import EuroIcon from "@material-ui/icons/Euro";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {
  PANEL_ROUTE,
  SENDSMS_ROUTE,
  ADMIN_PRICELIST_ROUTE,
  DEVICES_ROUTE,
  PRICES_ROUTE,
} from "../utils/consts";

export const mainListItems = (
  <div>
    <ListItem button component="a" href={PANEL_ROUTE}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button button component="a" href={SENDSMS_ROUTE}>
      <ListItemIcon>
        <MessageIcon />
      </ListItemIcon>
      <ListItemText primary="Send SMS" />
    </ListItem>
    <ListItem button component="a" href={DEVICES_ROUTE}>
      <ListItemIcon>
        <PhonelinkSetup />
      </ListItemIcon>
      <ListItemText primary="Devices" />
    </ListItem>
    <ListItem button component="a" href={PRICES_ROUTE}>
      <ListItemIcon>
        <EuroIcon />
      </ListItemIcon>
      <ListItemText primary="Prices" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Admin</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <MessageIcon />
      </ListItemIcon>
      <ListItemText primary="SMS" />
    </ListItem>
    <ListItem button component="a" href={ADMIN_PRICELIST_ROUTE}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Routes" />
    </ListItem>
  </div>
);
